var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.visible,
            closable: true,
            size: "normal",
            title: "确认该员工入职",
            "ok-text": "保存",
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c(
            "a-checkbox",
            {
              model: {
                value: _vm.probation,
                callback: function ($$v) {
                  _vm.probation = $$v
                },
                expression: "probation",
              },
            },
            [_vm._v("无试用期")]
          ),
          _c(
            "a-checkbox",
            {
              model: {
                value: _vm.entryRegistration,
                callback: function ($$v) {
                  _vm.entryRegistration = $$v
                },
                expression: "entryRegistration",
              },
            },
            [_vm._v("发送入职登记表")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }